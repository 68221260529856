<template>
  <div class="mod-config">
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :inline="true" :model="dataForm">
      <!--    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">-->
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="项目名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="setAttrabute()" :disabled="projectEnabled===1">设置项目属性</el-button>
        <el-button type="danger" @click="resetAttrabute()" :disabled="projectEnabled===0">重置项目属性</el-button>
        <el-button @click="selectByNameAndInit()" :disabled="projectEnabled===0">查询</el-button>
        <el-button @click="init()" :disabled="projectEnabled===0">重置</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()" :disabled="projectEnabled===0">新增</el-button>
        <el-button  type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 && projectEnabled===0">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" border width="80%" @selection-change="selectionChangeHandle">
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50">
      </el-table-column>
      <template v-for="(item,index) in tableCols">
        <el-table-column :prop="item.cols"
                         :label="item.name"
                         :key="index">
        </el-table-column>
      </template>
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="200"
          label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small "@click="checkHandle(scope.row.id)" >查看</el-button>
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)" >修改</el-button>
          <el-button type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <project-attribute-add v-if="projectAttributeAddVisible" ref="projectAttributeAdd" @refreshDataList="init"></project-attribute-add>
    <project-add-or-update v-if="addOrUpdateVisible" ref="ProjectAddOrUpdate" @refreshDataList="init"></project-add-or-update>
<!--    <create-table v-if="createTablevisible" ref="createTable" @refreshDataList="init"></create-table>-->
  </div>
</template>

<script>
import ProjectAttributeAdd from "./project-attribute-add";
import ProjectAddOrUpdate from "./prject-add-or-update";
export default {
  name: "projectManage",
  components: {ProjectAddOrUpdate, ProjectAttributeAdd},
  data () {
    return {
      show: false,
      visible: false,
      projectEnabled: false,
      dataForm: {
        key: ''
      },
      dataListSelections: [],
      tableData: [],
      tableCols: [],
      isLeaf: 0,
      tableId: 0,
      itemId: 0,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
      projectAttributeAddVisible: false,
      enterState: 0,   //进入状态，0代表直接进入，2代表搜索进入
    }
  },
  componets:{
    ProjectAttributeAdd,
    ProjectAddOrUpdate,
  },
  methods: {
    checkHandle(id){
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/projectToLevel?id='+id),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            console.log(data)
            if(data.state===0){
              this.$message({
                message: '当前项目没有绑定层级关系',
                type: 'success',
              })
            }
            else {
              window.sessionStorage.setItem("enterState",0)
              window.sessionStorage.setItem("tableId",data.tableId)
              window.sessionStorage.setItem("parentRowId",0)
              window.sessionStorage.setItem("projectId",id)
              this.$router.push({
                name: 'universal-table'
              })
            }
          } else {
            this.$message.error(data.msg)
          }
        })
    },
    resetAttrabute(){
      this.$confirm(`确定对对项目属性进行重置？这会导致已有的项目数据被清空`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/dropProject'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.tableCols=[]
                this.tableData=[]
                this.init()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    getDataList(){
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/hasProject'),
        method: 'get',
      }).then(({data}) =>{
        this.projectEnabled=data.count;
        return this.projectEnabled
      }).then(()=>{
        if(this.projectEnabled===1){
          this.$http({
            url: this.$http.adornUrl('/levelManage/projectattributes/getCols'),
            method: 'get',
          }).then(({data})=>{
            this.tableCols=[]
            for(let i=0;i<data.data.length;i++)
            {
              this.tableCols.push({
                name: data.data[i].remark,
                cols: data.data[i].name,
              })
            }
          }).then(()=>{
            this.$http({
              url: this.$http.adornUrl('/levelManage/projectattributes/getProjectInfo?page='+this.pageIndex+'&size='+this.pageSize  ),
              method: 'get',
            }).then(({data})=>{
              this.tableData=data.data;
              this.totalPage=data.count;
            })
          })
        }
      })
    },
    init(){
      this.enterState=0
      this.getDataList()
    },
    setAttrabute(){
      this.projectAttributeAddVisible=true
      this.$nextTick(() => {
        this.$refs.projectAttributeAdd.init()
      })
    },
    selectByName() {
      this.enterState=2
      window.sessionStorage.setItem("enterState",this.enterState)
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/selectByName?name='+this.dataForm.key+'&page='+this.pageIndex+'&limit='+this.pageSize),
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tableData=data.data;
          this.totalPage = data.count
          this.showpage=true;
        } else {
          this.dataList = []
          this.totalPage = 0
          this.showpage=true;
        }
        this.dataListLoading = false
      })
    },
    selectByNameAndInit()
    {
      this.pageIndex=1
      this.selectByName()
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else
      {
        this.selectByNameAndInit()
      }
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else
      {
        this.selectByName()
      }
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.ProjectAddOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle (id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?这也会解除${id ? '它' : '它们'}和层级间的关系`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
  },
  mounted() {
      this.init();
  }
}
</script>

<style scoped>

</style>